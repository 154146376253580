import { KeycloakConfig } from 'keycloak-js/dist/keycloak';
import axios from "axios";
import { Nullable } from "../../shared/models/types";

interface Config {
  logoUrl: string
  sso: KeycloakConfig
}

let config: Nullable<Config>;

export const getConfig = (): Nullable<Config> => config;

export const getConfigAsync = async (): Promise<Nullable<Config>> => {
  if (config == null) {
    const xhrLocale = await axios.get('./assets/config.json');
    if (xhrLocale.status === 200) {
      config = xhrLocale.data as Config;
    }
  }
  return config;
};