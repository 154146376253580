import { effect, inject, Injectable, NgZone, Signal, signal } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { toSignal } from "@angular/core/rxjs-interop";
import { NGXLogger } from "ngx-logger";
import { environment as Config } from "../../../environments/environment";
import { Nullable } from '../../shared/models/types';

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService {

  readonly #swUpdate = inject(SwUpdate);
  readonly #zone = inject(NgZone);
  readonly #logger = inject(NGXLogger);
  readonly #latestVersionHash = signal<Nullable<string>>(null);
  readonly #versionUpdates = toSignal(this.#swUpdate.versionUpdates, { initialValue: null });

  constructor () {
    if(this.#swUpdate.isEnabled) {
      // Controllo nuova versione ogni x minuti
      this.#zone.runOutsideAngular(() => {
        setInterval(() => {
          this.#swUpdate.checkForUpdate();
        }, Config.newVersionCheckTime * 60000);
      });

      effect(() => {
        const versionUpdates = this.#versionUpdates();
        if(versionUpdates) {
          switch (versionUpdates.type) {
            case 'VERSION_DETECTED':
              this.#logger.info(`Downloading new app version: ${versionUpdates.version.hash}`);
              break;
            case 'VERSION_READY':
              this.#logger.info(`Current app version: ${versionUpdates.currentVersion.hash}`);
              this.#logger.info(`New app version ready for use: ${versionUpdates.latestVersion.hash}`);
              this.#latestVersionHash.set(versionUpdates.latestVersion.hash);
              break;
            case 'VERSION_INSTALLATION_FAILED':
              this.#logger.error(`Failed to install app version '${versionUpdates.version.hash}': ${versionUpdates.error}`);
              break;
          }
        }
      });
    }
  }

  public get latestVersionHash (): Signal<Nullable<string>> {
    return this.#latestVersionHash.asReadonly();
  }

}