import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateFn,
  createUrlTreeFromSnapshot,
  GuardResult,
  MaybeAsync,
  Router
} from '@angular/router';
import { inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { filterNotNull } from "../../../shared/utility/rxjs-utils";
import { injectQuery } from '@tanstack/angular-query-experimental';
import { CustomerQueryService } from '../../../core/customers/services/customer-query.service';
import { toObservable } from '@angular/core/rxjs-interop';

export const canViewCustomerAssets: CanActivateFn = (route: ActivatedRouteSnapshot): MaybeAsync<GuardResult> => {
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const customerQueryService = inject(CustomerQueryService);
  const customers = injectQuery(() => customerQueryService.getCustomersXLoggedOpe(false, null));

  return toObservable(customers.data).pipe(
    filterNotNull,
    filter(customers => customers.length > 0),
    map(customers => {
      const tree = createUrlTreeFromSnapshot(activatedRoute.snapshot, []);
      const customerId = route.queryParams['cId'];
      const isActive = customers.find(cu => cu.id === Number(customerId))?.active;
      let path: any[] = [];
      if (route.data['outletModal']) {
        if ('primary' in tree.root.children) {
          path = [{ outlets: { modal: null, primary: tree.root.children['primary'].toString() } }];
        } else {
          path = [{ outlets: { modal: null } }];
        }
      } else {
        path = ['asset'];
      }

      return (!isActive && customerId != null) ? router.createUrlTree(path,{ queryParams: { ...route.queryParams, cId: null, dId: null } }) : true;
    })
  );
};