import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { JobWrapperComponent } from '../../../shared/components/job-wrapper/job-wrapper.component';

@Component({
  selector: 'ta-report-wrapper',
  template: `
    <ta-job-wrapper [visible]="true">
      <router-outlet></router-outlet>
    </ta-job-wrapper>
  `,
  imports: [RouterOutlet, JobWrapperComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportWrapperComponent {}
