import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerConfig } from './hammerJs/hammer-config';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './router/custom-reuse-strategy';

export const provideHammer = (): EnvironmentProviders => makeEnvironmentProviders([
  { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }
]);

export const provideCustomReuseStrategy = (): EnvironmentProviders => makeEnvironmentProviders([
  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy }
]);
